<template>
  <div>
    <div v-if="!configurationStep"
         class="save-space-form">
      <h3 class="save-space-form__title">
        NEW BUILDING
      </h3>
      <q-field :errorLabel="requiredMessage"
               :error="inputHasError('name')">
        <q-input v-model="saveSpaceForm.name"
                 :class="formInputClass('name')"
                 type="text"
                 name="name"
                 class="form-input form-input--name"
                 floatLabel="Building Name"
                 autocomplete="off"
                 @keyup.enter="$refs.country.focus()" />
      </q-field>
      <q-field :errorLabel="countryErrorMessage"
               :error="inputHasError('country')">
        <q-input ref="country"
                 v-model="saveSpaceForm.country"
                 :class="formInputClass('country')"
                 name="country"
                 class="form-input form-input--country"
                 placeholder="Type your country"
                 floatLabel="Country"
                 @keyup.enter="$refs.city.focus()">
          <q-autocomplete :staticData="{ field: 'value', list: countries }"
                          :minCharacters="1"
                          class="form-input__autocomplete"
                          @selected="setCountryCode" />
        </q-input>
      </q-field>
      <div class="grouped-field">
        <q-field :errorLabel="requiredMessage"
                 :error="inputHasError('address.city')"
                 class="form-input--city">
          <q-input ref="city"
                   v-model="saveSpaceForm.address.city"
                   :class="formInputClass('address.city')"
                   type="text"
                   name="city"
                   class="form-input"
                   floatLabel="City"
                   autocomplete="off"
                   @keyup.enter="$refs.zip.focus()" />
        </q-field>
        <q-field :errorLabel="requiredMessage"
                 :error="inputHasError('address.zipCode')"
                 class="form-input--zip">
          <q-input ref="zip"
                   v-model="saveSpaceForm.address.zipCode"
                   :maxLength="5"
                   :class="formInputClass('address.zipCode')"
                   type="text"
                   name="zip"
                   class="form-input"
                   floatLabel="Zip Code"
                   autocomplete="off"
                   @keyup.enter="$refs.address.focus()" />
        </q-field>
      </div>
      <q-field :errorLabel="requiredMessage"
               :error="inputHasError('address.line1')">
        <q-input ref="address"
                 v-model="saveSpaceForm.address.line1"
                 :class="formInputClass('address.line1')"
                 type="text"
                 name="address"
                 class="form-input form-input--address"
                 floatLabel="Address"
                 autocomplete="off"
                 @keyup.enter="saveBuilding" />
      </q-field>
      <div v-if="buildingIsBeingSaved"
           class="form-create o-button o-button--black">
        <qt-spinner :size="20"
                    class="spinner-loader" />
      </div>
      <div v-else
           class="form-create o-button o-button--shadows o-button--hover o-button--black"
           @click="saveBuilding">
        CREATE
      </div>
    </div>
    <div v-else
         class="save-space-form save-space-form--configuration">
      <h3 class="save-space-form__title">
        BUILDING CREATED!
      </h3>
      <div class="form-recap">
        <div class="form-recap__item">
          {{ saveSpaceForm.name }}
        </div>
        <div class="form-recap__item">
          {{ saveSpaceForm.country }}
        </div>
        <div class="form-recap__item">
          {{ `${saveSpaceForm.address.zipCode} ${saveSpaceForm.address.city}` }}
        </div>
        <div class="form-recap__item">
          {{ saveSpaceForm.address.line1 }}
        </div>
      </div>
      <div class="configuration-step">
        <div v-if="buildingUuid !== ''"
             class="form-create o-button o-button--shadows o-button--hover o-button--black"
             @click="goToConfiguration">
          CONFIGURE YOUR BUILDING
        </div>
        <span class="skip-link"
              @click="skipConfiguration">
          skip configuration
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import api from '@/services/api';
import Notification from '@/services/utils/notification.js';
import QtSpinner from '@/app/components/ui/spinner.vue';

function countryExists() {
  const countryExists = this.countries.find(c => c.label.toLowerCase() === this.saveSpaceForm.country.toLowerCase());
  return countryExists ? true : false;
}

export default {
  name: 'UploadForm',
  components: {
    QtSpinner,
  },
  props: {
    ifcData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      checkForm: false,
      requiredMessage: 'Required field',
      unknownCoutryMessage: 'Country not reconized',
      saveSpaceForm: {
        name: '',
        country: '',
        address: {
          city: '',
          zipCode: '',
          line1: '',
          countryAlpha2: '',
        },
      },
      configurationStep: false,
      buildingUuid: '',
      buildingIsBeingSaved: false,
    };
  },
  validations: {
    saveSpaceForm: {
      name: { required },
      country: {
        required,
        countryExists,
      },
      address: {
        city: { required },
        zipCode: { required },
        line1: { required },
      },
    },
  },
  computed: {
    countries() {
      return this.$store.state.countries.collection;
    },
    countryErrorMessage() {
      if (this.$v.saveSpaceForm.country.required) return this.unknownCoutryMessage;
      else return this.requiredMessage;
    },
  },
  created() {
    if (this.countries.length === 0) this.$store.dispatch('countries/fetch');
  },
  methods: {
    async saveBuilding() {
      this.checkForm = true;
      if (this.$v.$invalid) return;
      try {
        this.buildingIsBeingSaved = true;
        this.saveSpaceForm.metadata = this.ifcData.global;
        this.saveSpaceForm.floors = this.ifcData.floors;
        const response = await api.building.create(this.saveSpaceForm);
        this.buildingUuid = response.data;
        Notification.ToastSuccess('Building created');
        this.$store.dispatch('building/fetch');
        this.configurationStep = true;
        this.buildingIsBeingSaved = false;
      } catch (err) {
        this.buildingIsBeingSaved = false;
        Notification.ToastError(err, 'Creation failed');
      }
    },
    goToConfiguration() {
      this.$emit('creation');
      const building = this.$store.getters['building/read'](this.buildingUuid);
      this.$store.commit('building/setSelectedBuilding', building);
      this.$router.push({ name: 'configuration', params: { firstConfig: true } });
    },
    skipConfiguration() {
      this.$emit('creation');
      this.$router.push({ name: this.$store.getters['user/defaultPage'] });
    },
    setCountryCode(country) {
      this.saveSpaceForm.address.countryAlpha2 = country.alpha2;
    },
    inputHasError(inputName) {
      return this.checkForm && _.get(this.$v.saveSpaceForm, inputName).$invalid;
    },
    formInputClass(inputName) {
      return {
        'form-input--error': this.inputHasError(inputName),
      };
    },
    countryExists() {
      return this.countries.find(c => c.label.toLowerCase() === this.saveSpaceForm.country.toLowerCase());
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.save-space-form
  padding-left 32px
  border-left 1px solid $dark
  .spinner-loader
    stroke white
    fill white
  .save-space-form__title
    margin 0
    letter-spacing 0.4px
    font-weight 700
    font-size $fs-h1
  .q-input.form-input--error
    >>> input
      border 1px solid $negative
  .grouped-field
    display flex
    .form-input--city
      margin-right 8px
      width 60%
    .form-input--zip
      width 37%
  .q-field
    >>> .q-field-bottom
      padding-top 0
  .q-field.q-field-with-error
    margin-bottom 0
  .q-input
    margin 0
    padding 0
    &.q-if:before, &.q-if:after
      content none
    >>> .q-if-inner
      padding-top 20px
    >>> .q-if-label
      top 5px
      left 8px
      color black
      font-size $fs-body
    >>> .q-if-label-above
      transform scale(0.9) translate(-8px, -25px)
    >>> input
      margin 0
      padding 16px 8px
      border 1px solid $dark
      border-radius 4px
      color black
      font-size $fs-body
  .form-create
    margin-top 16px
    padding 8px 16px
    width 100%
    text-align center
    font-size $fs-h3
  .skip-link
    float right
    text-align center
    text-decoration underline
    font-size $fs-body
    cursor pointer

.save-space-form--configuration
  .form-recap
    display flex
    flex-direction column
    margin 30px 0
    .form-recap__item
      height 30px
      font-size $fs-h2

.form-input__autocomplete
  box-shadow none
  >>> .q-list
    padding 0
  &:hover
    >>> .q-list
      background-color white
</style>
