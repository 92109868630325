<template>
  <div :class="loadingBarClass"
       class="loading-bar"
  >
    <div class="bar-container">
      <div :class="barClass"
           :style="barWidth"
           class="bar"
      >
        <div v-if="isLoading"
             class="bar-tooltip__container"
        >
          <div class="bar-tooltip">
            <span class="bar-tooltip__loading-type">
              {{ whileLoading }}
            </span>
            <span class="bar-tooltip__percentage">
              {{ `${percentage}%` }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingBar',
  props: {
    whileLoading: {
      type: String,
      default: 'loading',
    },
    endLoading: {
      type: String,
      default: 'loaded',
    },
    percentage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    barWidth() {
      return {
        width: `${this.percentage}%`,
      };
    },
    isLoading() {
      return this.percentage > 0 && this.percentage < 100;
    },
    loadingBarClass() {
      return {
        'loading-bar--loading': this.isLoading,
      };
    },
    barClass() {
      return {
        'bar--in-progress': this.isLoading,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables';

.loading-bar
  width 100%
  transition margin .3s ease
  margin-top 35px
  .bar-container
    width 100%
    height 30px
    display flex
    background-color $light
    .bar
      position relative
      height 100%
      transition width 0.3s ease
      background linear-gradient(to right, $primary 70%, darken($dark-pink, 15%))
      &--in-progress
        border-right 4px solid white
        box-shadow 1px 0px 3px $grey
    .bar-tooltip__container
      position absolute
      top -30px
      right -2px
      z-index 200
    .bar-tooltip
      position relative
      display flex
      align-items center
      padding 4px 8px
      border-radius 18px
      font-size 0.8em
      text-transform uppercase
      background-color $dark
      color white
      .bar-tooltip__loading-type
        color $primary
        margin-right 4px
      &:after
        content ""
        position absolute
        z-index -1
        width 15px
        height 15px
        background-color $dark
        right 0px
        bottom 0px
</style>
