<template>
  <div class="file-input-form">
    <div class="file-input__container">
      <input ref="fileInput"
             type="file"
             class="file-input"
             @click="resetInput"
             @change="storeFile"
      >
      <label class="file-input__label"
             for="file"
      >
        {{ inputLabel }}
      </label>
    </div>
    <QBtn class="file-input__button"
          @click="uploadFile"
    >
      UPLOAD
    </QBtn>
  </div>
</template>

<script>
import axios from 'axios';
import api from '@/services/api';

export default {
  name: 'InputFile',
  props: {
    file: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      defaultLabel: 'Select a file or Drag&Drop',
    };
  },
  computed: {
    fileName() {
      if (this.file) return this.file.name;
      else return null;
    },
    inputLabel() {
      return this.fileName ? this.fileName : this.defaultLabel;
    },
  },
  methods: {
    storeFile(evt) {
      const file = evt.target.files[0] || null;
      if (!file) return;
      this.$emit('setData', {key: 'file', value: file});
    },
    async uploadFile() {
      if (!this.file) return;
      this.$emit('setData', {key: 'percentage', value: 0});
      this.$emit('setData', {key: 'isUploading', value: true});
      this.$emit('upload');
      const formData = new FormData();
      formData.append('file', this.file);
      const CancelToken = axios.CancelToken;
      const cancelSource = CancelToken.source();
      try {
        const response = await api.building.upload(this.fileName, formData, cancelSource, progressEvent => {
          const tmpCalc = progressEvent.loaded * 100;
          const progressPercentage = Math.round(tmpCalc / progressEvent.total);
          this.$emit('setData', {key: 'percentage', value: progressPercentage});
        });
        this.$emit('setData', {key: 'ifcData', value: { ...response.data, fileName: this.fileName }});
        this.$emit('setData', {key: 'uploadOk', value: true});
      } catch (error) {
        this.$emit('setData', {key: 'uploadOk', value: false});
        this.$emit('error');
      }
      this.$emit('setData', {key: 'isUploading', value: false});
    },
    resetInput() {
      this.$refs.fileInput.value = '';
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables';

.file-input-form
  display flex
  padding 4px 0
  .file-input__container
    flex 1
    border 2px dashed $grey
    border-radius 4px
    position relative
    &:hover
      border-color lighten($primary, 20%)
      .file-input__label
        color $primary
    .file-input
      width 100%
      height 100%
      margin 0
      padding 0
      opacity 0
      cursor pointer
    .file-input__label
      position absolute
      bottom 0
      height 100%
      width 100%
      display flex
      justify-content center
      align-items center
      color $dark
      pointer-events none
      font-size 1.3em
  .file-input__button
    margin-left 16px
    background-color $primary
    color white
    font-size 1.2em
    font-weight 700
    letter-spacing 0.4px
</style>
