<template>
  <OasisLoader class="loader"
    :height="size"
    />
</template>

<script>
import OasisLoader from '@/app/components/ui/oasis-loader.vue';

export default {
  name: 'QtSpinner',
  components: {
    OasisLoader,
  },
  props: {
    size: {
      type: String,
      default: '80',
    },
  },
};
</script>
