import { Notify } from 'quasar';

const notifOptions = {
  position: 'bottom-left',
};

export default {
  ToastSuccess: function(message) {
    Notify.create({
      message: message,
      type: 'positive',
      position: notifOptions.position,
    });
  },
  ToastError: function(err, defaultMessage) {
    const message =
      (err && err.response && err.response.data && typeof err.response.data === 'string' && err.response.data) ||
      defaultMessage ||
      (err && err.message) ||
      err ||
      'Error';
    Notify.create({
      message: message,
      type: 'negative',
      position: notifOptions.position,
    });
  },
};
