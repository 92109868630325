<template>
  <QModal v-model="show"
          class="upload-modal"
          @hide="emitClose">
    <div class="upload-modal__header">
      <div class="informations">
        <h3 class="informations__title">
          IFC FILE IMPORT
        </h3>
        <p class="informations__subtitle">
          Details on accepted file type<br>link to the documentation page for creating the file
        </p>
      </div>
      <FileInput class="upload-modal__input"
                :file="file"
                 @setData='setData'
                 @upload="fileError = false"
                 @error="displayError" />
    </div>
    <LoadingBar v-if="isUploading && percentage !== 100"
                :percentage="percentage"
                whileLoading="uploading"
                endLoading="uploaded"
                class="loading-bar" />
    <div v-if="isUploading && percentage === 100"
         class="upload__spinner-container">
      <!-- <Spinner
        class="upload__spinner" /> -->
      <q-spinner-dots size="30px"
                      color="black"
                      class="upload__spinner" />
      <span class="upload__spinner-message">
        Analysing IFC
      </span>
    </div>
    <div v-if="!isUploading && percentage === 100"
         class="upload-modal__main">
      <div v-if="!uploadOk && fileError"
           class="upload-error">
        <span class="upload-error__error-message">
          IFC FILE ERROR
        </span>
        regardez
        <span class="upload-error_doc-link">
          comment créer un fichier IFC
        </span>
      </div>
      <div v-if="uploadOk"
           class="upload-success">
        <BuildingInfo :ifcData="ifcData.global"
                      class="building-info" />
        <UploadForm class="building-form"
                    :ifcData="ifcData"
                    @creation="emitClose" />
      </div>
    </div>
  </QModal>
</template>

<script>
import LoadingBar from '@/app/components/ui/loading-bar.vue';
import FileInput from '@/app/views/ifc-upload/file-input.vue';
import BuildingInfo from '@/app/views/ifc-upload/building-info.vue';
import UploadForm from '@/app/views/ifc-upload/form.vue';

export default {
  name: 'UploadModal',
  components: {
    FileInput,
    LoadingBar,
    BuildingInfo,
    UploadForm,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fileError: false,
      file: null,
      isUploading: false,
      percentage: 0,
      uploadOK: false,
      ifcData: null,
    };
  },
  methods: {
    emitClose() {
      this.$emit('close');
      this.fileError = false;
      this.file = null;
      this.percentage = 0;
      this.isUploading = false;
      this.uploadOK = false;
    },
    displayError() {
      this.fileError = true;
    },
    setData({key, value}) {
      this[key] = value;
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.upload-modal
  >>> .modal-content
    display flex
    flex-direction column
    overflow hidden
    padding 16px
    width 900px
    .upload-modal__header
      display flex
      .informations
        flex-basis 40%
        padding-right 16px
        color black
        .informations__title
          margin 0
          margin-bottom 4px
          letter-spacing 0.4px
          font-weight 700
          font-size $fs-h1
          line-height 1
        .informations__subtitle
          margin 0
          font-size $fs-little
          line-height 1.3
      .upload-modal__input
        flex-basis 60%
        height 50px
    .loading-bar
      // margin 20px 0
    .upload__spinner-container
      display flex
      flex-direction column
      align-items center
      margin-top 8px
      padding 32px
      background-color $light
      .upload__spinner
        width 50px
        height @width
      .upload__spinner-message
        margin-top 10px
        text-align center
        font-weight 700
        font-size 1.3em
    .upload-modal__main
      display flex
      .upload-success
        display flex
        margin-top 8px
        padding 32px
        width 100%
        background $light
        .building-info
          width 60%
        .building-form
          width 40%
      .upload-error
        display flex
        flex 1
        justify-content center
        align-items center
        margin-top 8px
        padding 32px
        font-size 1.1em
        & > *
          margin 0 4px
        .upload-error__error-message
          font-weight 700
          font-size 1.3em
        .upload-error_doc-link
          text-decoration underline
          font-style italic
          cursor pointer
      .upload-modal__form
        flex-basis 40%
        margin-left auto
</style>
